
import * as Yup from "yup";
import { useI18n } from "vue-i18n/index";
import axios, { AxiosResponse } from "axios";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiRoutes from "@/core/config/ApiRoutes";
import { hideModal } from "@/core/helpers/dom";
import { checkField, resetField, enableSubmitButton, disableSubmitButton } from "@/core/helpers/form";
import FieldError from "@/components/modals/shared/FieldError.vue";

export default defineComponent({
    name: "edit-infrastructure-modal",
    emit: ["refreshData"],
    props: {
        data: null
    },
    components: {
        ErrorMessage,
        Field,
        Form,
        FieldError,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();
        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const editInfrainfrastructureModalRef = ref<null | HTMLElement>(null);

        let isUpdate = false as boolean;
        let errors: any = ref();

        const infrastructureL1Names = ref([]);
        const infrastructureL2Names = ref([]);
        const infrastructureL3Names = ref([]);
        const infrastructureL4Names = ref([]);
        const infrastructureL5Names = ref([]);

        const infrastructureDefault = {
            infrastructureL1Name: "",
            infrastructureL1Description: "",
            infrastructureL2Name: "",
            infrastructureL2Description: "",
            infrastructureL3Name: "",
            infrastructureL3Description: "",
            infrastructureL4Name: "",
            infrastructureL4Description: "",
            infrastructureL5Name: "",
            infrastructureL5Description: "",
        };
        const infrastructureData = ref<any>({});
        Object.assign(infrastructureData.value, infrastructureDefault);

        const validationSchema = Yup.object().shape({
            // infrastructureL1Name: Yup.string().required().label(translate("infrastructureL1NameLabel")),
            // infrastructureL1Description: Yup.string().nullable().label(translate("infrastructureL1DescriptionLabel")),
            // infrastructureL2Name: Yup.string().nullable().label(translate("infrastructureL2NameLabel")),
            // infrastructureL2Description: Yup.string().nullable().label(translate("infrastructureL2DescriptionLabel")),
            // infrastructureL3Name: Yup.string().nullable().label(translate("infrastructureL3NameLabel")),
            // infrastructureL3Description: Yup.string().nullable().label(translate("infrastructureL3DescriptionLabel")),
            // infrastructureL4Name: Yup.string().nullable().label(translate("infrastructureL4NameLabel")),
            // infrastructureL4Description: Yup.string().nullable().label(translate("infrastructureL4DescriptionLabel")),
            // infrastructureL5Name: Yup.string().nullable().label(translate("infrastructureL5NameLabel")),
            // infrastructureL5Description: Yup.string().nullable().label(translate("infrastructureL5DescriptionLabel")),
        });

        const submit = () => {
            errors.value = null;

            disableSubmitButton(submitButtonRef);

            axios.post(ApiRoutes.infrastructures.updateOrCreate, infrastructureData.value)
                .then((response: AxiosResponse) => {

                    enableSubmitButton(submitButtonRef);

                    if (response.data && response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            hideModal(editInfrainfrastructureModalRef.value);

                            emit("refreshData");
                        });

                    } else if (response.data && response.data.error) {

                        let error = (Array.isArray(response.data.error)) ? response.data.error.join('</li><li>') : response.data.error;

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><div class="alert alert-danger"><ul class="list-unstyled mb-0"><li>' + error + "</li></ul></div>",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    enableSubmitButton(submitButtonRef);

                    if (error.response.status == 422) {
                        errors.value = error.response.data.errors;
                    } else {

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                });
        };

        const getInfrainfrastructuresNames = async () => {
            let response = await axios.get(ApiRoutes.infrastructures.names, { params: { level: 1 } });
            infrastructureL1Names.value = response.data;

            response = await axios.get(ApiRoutes.infrastructures.names, { params: { level: 2 } });
            infrastructureL2Names.value = response.data;

            response = await axios.get(ApiRoutes.infrastructures.names, { params: { level: 3 } });
            infrastructureL3Names.value = response.data;

            response = await axios.get(ApiRoutes.infrastructures.names, { params: { level: 4 } });
            infrastructureL4Names.value = response.data;

            response = await axios.get(ApiRoutes.infrastructures.names, { params: { level: 5 } });
            infrastructureL5Names.value = response.data;
        };

        onMounted(() => {
            const modal = document.getElementById('kt_modal_edit_infrastructure');

            if (modal) modal.addEventListener('hidden.bs.modal', function(event) {
                Object.assign(infrastructureData.value, infrastructureDefault);
            });

            if (modal) modal.addEventListener('shown.bs.modal', function() {
                isUpdate = false;
                errors.value = null;

                if (props.data && infrastructureData.value) {
                    isUpdate = true;

                    // Fill infrastructure fields
                    Object.keys(infrastructureData.value).forEach((key) => {
                        infrastructureData.value[key] = props.data[key];
                    });
                }
            });

            getInfrainfrastructuresNames();
        });

        return {
            translate,

            submitButtonRef,
            editInfrainfrastructureModalRef,

            validationSchema,

            errors,
            resetField,
            checkField,

            infrastructureData,
            infrastructureL1Names,
            infrastructureL2Names,
            infrastructureL3Names,
            infrastructureL4Names,
            infrastructureL5Names,

            submit,
        };
    },
});
